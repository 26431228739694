import React from "react";
import { ReactFirebaseApp } from "./Firebase";
import "./Home.css";

class Home extends React.Component {
  getCurrentUser(event) {
    console.error(">>>getCurrentUser()", event);
    const currentUserAPI = ReactFirebaseApp.firebaseGetHTTPSCallableInstance("currentUser");
    currentUserAPI()
      .then((result) => {
        console.error(">>>result", result);
      })
      .catch((error) => {
        console.error(">>>error", error);
      });
  }

  getUsers(event) {
    console.error(">>>getUsers()", event);
    const usersAPI = ReactFirebaseApp.firebaseGetHTTPSCallableInstance("users");
    usersAPI()
      .then((result) => {
        console.error(">>>result", result);
      })
      .catch((error) => {
        console.error(">>>error", error);
      });
  }

  sendMessage(event) {
    console.error(">>>sendMessage()", event);
    const sendMessageAPI = ReactFirebaseApp.firebaseGetHTTPSCallableInstance("sendMessage");
    sendMessageAPI()
      .then((result) => {
        console.error(">>>result", result);
      })
      .catch((error) => {
        console.error(">>>error", error);
      });
  }

  render() {
    return (
      <div className="Home">
        Home
        <button onClick={ReactFirebaseApp.firebaseSignOut}>Sign Out</button>
        <button onClick={ReactFirebaseApp.firebaseSendEmailVerification}>Send e-mail verification</button>
        <button onClick={this.getCurrentUser}>getCurrentUser</button>
        <button onClick={ReactFirebaseApp.firebaseGetCurrentUser}>getCurrentUserClient</button>
        <button onClick={this.getUsers}>getUsers</button>
        <button onClick={ReactFirebaseApp.firebaseGetUsers}>getUsersClient</button>
        <button onClick={this.sendMessage}>sendMessage</button>
      </div>
    );
  }
}

export default Home;
