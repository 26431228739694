import React from "react";
import { ReactFirebaseApp } from "./Firebase";
import "firebaseui/dist/firebaseui.css";
import "./Login.css";

class Login extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      try {
        ReactFirebaseApp.firebaseAuthUIStart("#firebaseAuthUIContainer");
      } catch(error) {} // catch "Error: Could not find the FirebaseUI widget element on the page."
    }, "1000");
  }

  render() {
    return (
      <div id="firebaseAuthUIContainer" className="Login"/>
    );
  }
}

export default Login;
