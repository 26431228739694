import { ReactFirebaseApp } from "./Firebase";

export const RADataProvider = {
  getList: async (resource, params) => {
    if (resource === "users") {
      const users = await ReactFirebaseApp.firebaseGetUsers();
      return {
        data: users,
        total: users.length
      };
    }
  }
};
