import { ReactFirebaseApp } from "./Firebase";

export const RAAuthProvider = {
  // called when the user attempts to log in
  login: ({ username }) => {
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    ReactFirebaseApp.firebaseSignOut();
    return Promise.resolve();
  },
  // get the user's profile
  getIdentity: async () => {
    const user = await ReactFirebaseApp.firebaseGetCurrentUser();
    return Promise.resolve({
      id: user.email,
      fullName: user.displayName,
      avatar: null
    });
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    return Promise.resolve();
  }
};
