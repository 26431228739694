import React from "react";
import { Admin, Resource } from "react-admin";
import { RALayout } from "./RALayout";
import { RAAuthProvider } from "./RAAuthProvider";
import { RADataProvider } from "./RADataProvider";
import Login from "./Login";
import Home from "./Home";
import { UsersList } from "./UsersList";
import { initializeReactFirebaseApp } from "./Firebase";
import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    initializeReactFirebaseApp(this)
      .then(() => {
        console.info("ReactFirebaseApp initialization completed.");
      })
      .catch((error) => {
        console.error("ReactFirebaseApp initialization error:", error);
      });
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        navigator.serviceWorker.onmessage = (event) => {
          this.messageHandler(event.data);
        };
      });
    }
  }

  messageHandler(payload) {
    // https://firebase.google.com/docs/cloud-messaging/js/send-multiple#receive_and_handle_topic_messages
    if (
      payload &&
      payload.origin &&
      (payload.origin === "FOREGROUND_MESSAGE" ||
        payload.origin === "BACKGROUND_MESSAGE")
    ) {
      console.error(">>>messageHandler payload", payload);
    }
  }

  render() {
    console.error(">>>this.state.user", this.state.user);
    if (this.state.user) {
      return (
        <Admin layout={RALayout} dashboard={Home} authProvider={RAAuthProvider} dataProvider={RADataProvider}>
          <Resource name="users" list={UsersList}/>
        </Admin>
      );
    } else {
      return <Login />;
    }
  }
}

export default App;
