import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import "./UsersList.css";

export const UsersList = () => (
  <List>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="email"/>
      <TextField source="displayName"/>
    </Datagrid>
  </List>
);
