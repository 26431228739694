// Import the functions you need from the SDKs you need
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut, sendEmailVerification, EmailAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable, httpsCallableFromURL } from "firebase/functions";
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, getDocs, arrayUnion, serverTimestamp } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import * as firebaseui_en from "firebaseui";
//import * as firebaseui_it from "firebaseui/dist/npm__it";

export let ReactFirebaseApp;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseAppConfig = {
  apiKey: "AIzaSyBgudzeBNeZHLmnTVF1VbkR7gzwyyMlGKk",
  //authDomain: "zeta-range-312010.firebaseapp.com",
  authDomain: "firebase.goolex.it",
  projectId: "zeta-range-312010",
  storageBucket: "zeta-range-312010.appspot.com",
  messagingSenderId: "1098387976511",
  appId: "1:1098387976511:web:4f08e9408f6d36f4d5638a",
  measurementId: "G-C51PR7TZEC"
};

// https://cloud.google.com/iap/docs/using-firebaseui?hl=en
const firebaseAuthUIConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      return false;
    }
  },
  signInOptions: [
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true
    },
    {
      provider: "oidc.firebase.goolex.it",
      providerName: "OpenID Connect GooLex"
    },
    {
      provider: "saml.firebase.goolex.it",
      providerName: "SAML GooLex"
    }
  ]
};

export async function initializeReactFirebaseApp(reactApp) {
  console.info("ReactFirebaseApp initialization started.");
  ReactFirebaseApp = reactApp;
  ReactFirebaseApp.firebaseApp = initializeApp(firebaseAppConfig);
  ReactFirebaseApp.firebaseAnalytics = getAnalytics(ReactFirebaseApp.firebaseApp);
  ReactFirebaseApp.firebaseAuth = getAuth(ReactFirebaseApp.firebaseApp);
  const language = navigator.language.substring(0, 2);
  ReactFirebaseApp.firebaseAuth.languageCode = language;
  let firebaseui;
  if (language === "it") {
    firebaseui = await import("firebaseui/dist/npm__it"); // import * as firebaseui_it from "firebaseui/dist/npm__it"
  } else {
    firebaseui = await import("firebaseui"); // import * as firebaseui_en from "firebaseui";
  }
  try {
    ReactFirebaseApp.firebaseAuthUI = new firebaseui.auth.AuthUI(ReactFirebaseApp.firebaseAuth);
  } catch (error) {} // catch "Error: An AuthUI instance already exists for the key "[DEFAULT]"" during development server hot reload
  ReactFirebaseApp.fireabseFunctions = getFunctions(ReactFirebaseApp.firebaseApp, "europe-west1");
  ReactFirebaseApp.firebaseFirestore = getFirestore(ReactFirebaseApp.firebaseApp);
  ReactFirebaseApp.firebaseMessaging = getMessaging(ReactFirebaseApp.firebaseApp);

  ReactFirebaseApp.firebaseAuthUIStart = function (cssSelector) {
    ReactFirebaseApp.firebaseAuthUI.start(cssSelector, firebaseAuthUIConfig);
  };

  ReactFirebaseApp.firebaseSignOut = function () {
    signOut(ReactFirebaseApp.firebaseAuth)
      .then(() => {
        console.info("The user has successfully signed out.");
      })
      .catch((error) => {
        console.error("Error on the user sign out procedure:", error);
      });
  };

  ReactFirebaseApp.firebaseSendEmailVerification = function () {
    sendEmailVerification(ReactFirebaseApp.firebaseAuth.currentUser)
      .then(() => {
        console.info("The email verification was successfully sent.");
      })
      .catch((error) => {
        console.error("Error sending the email verification:", error);
      });
  };

  ReactFirebaseApp.firebaseGetHTTPSCallableInstance = function (api) {
    return httpsCallableFromURL(ReactFirebaseApp.fireabseFunctions, "https://europe-west1-zeta-range-312010.cloudfunctions.net/GooLexFirebaseReactPWA1API/" + api);
  };

  ReactFirebaseApp.firebaseRegisterFCMToken = async function (user) {
    if (user && "serviceWorker" in navigator) {
      const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
      const token = await getToken(ReactFirebaseApp.firebaseMessaging, {
        serviceWorkerRegistration,
        vapidKey: "BOUprjpuCQkAfASKjP07QfQe6VhDRdyH-TF9imrqZfux-oG1RGq4LDUc76l78GL_XAveVYGaAXPPKW1Bx9C0dx4"
      });
      const docRef = doc(ReactFirebaseApp.firebaseFirestore, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          fcmTokens: arrayUnion(token),
          updateTime: serverTimestamp()
        });
      }
    }
  };

  onAuthStateChanged(ReactFirebaseApp.firebaseAuth, (user) => {
    ReactFirebaseApp.setState({ user });
    ReactFirebaseApp.firebaseRegisterFCMToken(user);
  });

  onMessage(ReactFirebaseApp.firebaseMessaging, (payload) => {
    payload.origin = "FOREGROUND_MESSAGE";
    ReactFirebaseApp.messageHandler(payload);
  });

  ReactFirebaseApp.firebaseGetCurrentUser = async function () {
    const user = ReactFirebaseApp.state.user;
    const docRef = doc(ReactFirebaseApp.firebaseFirestore, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      user.firestoreUser = docSnap.data();
      user.displayName ||= user.firestoreUser.displayName;
    }
    console.error(">>>ReactFirebaseApp.firebaseGetCurrentUser:", user);
    return user;
  };

  ReactFirebaseApp.firebaseGetUsers = async function () {
    const querySnap = await getDocs(collection(ReactFirebaseApp.firebaseFirestore, "users"));
    const r = [];
    querySnap.forEach((docSnap) => r.push({ id: docSnap.id, ...docSnap.data() }));
    console.error(">>>ReactFirebaseApp.firebaseGetUsers:", r);
    return r;
  };
}
